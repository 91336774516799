// descomentar para passar no test pipeline
export const httpProtocol = process.env.GATSBY_HTTP_PROTOCOL || `https://`;
export const clientHost =
  process.env.GATSBY_CLIENT_HOST || 'sandbox.nebuacloud.com';
export const serverHost =
  process.env.GATSBY_SERVER_HOST || 'sandboxapi.nebuacloud.com';
export const serverPort = process.env.GATSBY_SERVER_PORT || '443';


/* export const httpProtocol = process.env.GATSBY_HTTP_PROTOCOL || `http://`;
export const clientHost =
  process.env.GATSBY_CLIENT_HOST || 'localhost';
export const serverHost =
  process.env.GATSBY_SERVER_HOST || 'localhost';
export const serverPort = process.env.GATSBY_SERVER_PORT || '5000';
 */

export const loginRoute =
  process.env.GATSBY_SERVER_LOGIN_ROUTE || '/auth/resource';

export const loginSSOControlPanelRoute =
  process.env.GATSBY_SERVER_LOGIN_SSO || '/auth/store-soo';

export const signUpRoute =
  process.env.GATSBY_SERVER_SIGNUP_ROUTE || '/auth/create-account';

export const logoutRoute =
  process.env.GATSBY_SERVER_LOGOUT_ROUTE_ || '/auth/logout';

export const checkOutRoute =
  process.env.GATSBY_SERVER_CHECKOUT_ROUTE || '/v1/checkout/checkout/';

export const serverCookieName =
  process.env.GATSBY_SERVER_COOKIE_NAME || 'NEBUAUUI';

export const loginUrl = httpProtocol.concat(
  serverHost,
  ':',
  serverPort,
  loginRoute,
);

export const logoutUrl = httpProtocol.concat(
  serverHost,
  ':',
  serverPort,
  logoutRoute,
);

export const node_env = process.env.NODE_ENV;
export const signUpUrl = httpProtocol.concat(
  serverHost,
  ':',
  serverPort,
  signUpRoute,
);

export const checkOutUrl = httpProtocol.concat(
  serverHost,
  ':',
  serverPort,
  checkOutRoute,
);

export const loginSSOControlPanelURL = httpProtocol.concat(
  serverHost,
  ':',
  serverPort,
  loginSSOControlPanelRoute,
);

export const socketUrl =
  process.env.GATSBY_SOCKET_URL || 'http://localhost:4000';
